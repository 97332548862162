import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InvoicesApi from "api/invoices";
import {
  CircularProgress,
  Pagination,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "../components/ActionCell";
import { useTranslation } from "react-i18next";
import SoftButton from "components/SoftButton";

function InvoiceStatus() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);
    InvoicesApi.getInvoices(token, page)
      .then((response) => {
        setInvoices(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (field) => {
    let newSortOrder;

    if (field === "client_name") {
      newSortOrder = nameSortOrder === "asc" ? "desc" : "asc";
      setNameSortOrder(newSortOrder);
      sortInvoices(field, newSortOrder);
    } else if (field === "date_created") {
      newSortOrder = dateSortOrder === "asc" ? "desc" : "asc";
      setDateSortOrder(newSortOrder);
      sortInvoices(field, newSortOrder);
    }

    setAnchor(null); // Fermer le menu après le tri
  };

  const sortInvoices = (field, order) => {
    const sortedInvoices = [...invoices].sort((a, b) => {
      const valueA =
        field === "date_created" ? new Date(a[field]) : a[field]?.toLowerCase();
      const valueB =
        field === "date_created" ? new Date(b[field]) : b[field]?.toLowerCase();

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    setInvoices(sortedInvoices);
  };

  const columns = [
    { Header: t("Numéro de facture"), accessor: "number" },
    { Header: t("Client"), accessor: "client_name" },
    { Header: t("Date de création"), accessor: "date_created" },
    { Header: t("Action"), accessor: "action" },
  ];

  const rows = invoices.map((invoice) => ({
    id: invoice.id,
    number: invoice.number,
    client_name: invoice.client_name,
    date_created: new Date(invoice.date_created).toLocaleString(),
    action: <ActionCell id={invoice.id} />,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <SoftButton
            variant="outlined"
            color="dark"
            onClick={(e) => setAnchor(e.currentTarget)}
            sx={{ marginBottom: 2.5 }}
          >
            {t("Trier par nom ou date")} (⬆⬇)
          </SoftButton>
          <Menu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
          >
            <MenuItem onClick={() => handleSortChange("client_name")}>
              {t("Trier par Client")} ({nameSortOrder === "asc" ? "⬆" : "⬇"})
            </MenuItem>
            <MenuItem onClick={() => handleSortChange("date_created")}>
              {t("Trier par Date")} ({dateSortOrder === "asc" ? "⬆" : "⬇"})
            </MenuItem>
          </Menu>

          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  columnMinWidth={70}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 100,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default InvoiceStatus;
