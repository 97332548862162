import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import FormField from "../FormField";
import CategoriesApi from "api/categories";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import SupplierApi from "api/supplier";

const ProductInfo = forwardRef(({ onChange }, ref) => {
  const { t } = useTranslation();

  const [productName, setProductName] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [supplier, setSupplier] = useState([]);
  const [status, setStatus] = useState("active");
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [isTaxed, setIsTaxed] = useState(false);
  const [errors, setErrors] = useState({});

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  useEffect(() => {
    handleGetCategories();
    fetchSuppliers();
  }, []);

  useEffect(() => {
    onChange({
      productName,
      referenceCode,
      description,
      category,
      supplier,
      status,
      isTaxed,
    });
    // eslint-disable-next-line
  }, [
    productName,
    referenceCode,
    description,
    category,
    supplier,
    status,
    isTaxed,
  ]);

  const handleGetCategories = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    CategoriesApi.GetCategories(token)
      .then((response) => {
        setCategories(response.data.results);
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      });
  };

  const fetchSuppliers = async () => {
    const token = localStorage.getItem("token");
    try {
      const supplierData = await SupplierApi.GetSuppliersList(token);
      setSuppliers(supplierData.data.results);
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    switch (field) {
      case "productName":
        setProductName(value);
        break;
      case "referenceCode":
        setReferenceCode(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "category":
        setCategory(value);
        break;
      case "supplier":
        setSupplier(value);
        break;
      case "status":
        setStatus(value);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!productName)
      newErrors.productName = `${t("Le nom de produit est requis")}`;
    if (!referenceCode)
      newErrors.referenceCode = `${t("Le code de référence est requis")}`;
    if (!category) newErrors.category = `${t("La catégorie est requise")}`;
    if (!supplier) newErrors.supplier = `${t("Le fournisseur est requis")}`;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">
        {t("Informations sur le produit")}
      </SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label={t("Nom de produit")}
              placeholder={t("Nom de produit")}
              value={productName}
              error={!!errors.productName}
              helperText={errors.productName}
              onChange={(e) => handleInputChange("productName", e.target.value)}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.productName}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label={t("Code de référence")}
              placeholder="eg. 6191122554421"
              value={referenceCode}
              error={!!errors.referenceCode}
              helperText={errors.referenceCode}
              onChange={(e) =>
                handleInputChange("referenceCode", e.target.value)
              }
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.referenceCode}
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t("Catégorie")}
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                value={category}
                error={!!errors.category}
                helperText={errors.category}
                onChange={(value) => handleInputChange("category", value)}
                options={categories.map((cat) => ({
                  value: cat.id.toString(),
                  label: t(cat.category_name),
                }))}
              />
              <SoftTypography component="label" variant="caption" color="error">
                {errors.category}
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("Description")}{" "}
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  color="text"
                >
                  ({t("optionnel")})
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftEditor value={description} onChange={setDescription} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <SoftTypography variant="caption" fontWeight="bold">
                {t("Fournisseur")}
              </SoftTypography>
              <SoftSelect
                isMulti
                value={supplier}
                error={!!errors.supplier}
                helperText={errors.supplier}
                onChange={(selectedOptions) =>
                  handleInputChange("supplier", selectedOptions)
                }
                options={suppliers.map((supplier) => ({
                  value: supplier.id, // Supplier ID
                  label: supplier.name, // Display name
                }))}
              />
              <SoftTypography component="label" variant="caption" color="error">
                {errors.supplier}
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={11}>
              <Grid
                item
                xs={12}
                sm={8}
                display="flex"
                justifyContent="space-around"
              >
                <SoftBox display="flex" alignItems="center" mb={2}>
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <SoftTypography variant="caption" fontWeight="bold" ml={1}>
                    {status ? t("Actif") : t("Inactif")}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" mb={2}>
                  <Switch
                    checked={isTaxed}
                    onChange={(e) => setIsTaxed(e.target.checked)}
                  />
                  <SoftTypography variant="caption" fontWeight="bold" ml={1}>
                    {isTaxed ? t("Taxé") : t("Non Taxé")}
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
});

export default ProductInfo;
