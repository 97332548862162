import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import ActionCell from "./components/ActionCell";
import EventIcon from "@mui/icons-material/Event";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useTranslation } from "react-i18next";

// Mock data for the caisse
const caisseData = [
  {
    name: "Caisse Centrale",
    address: "123 Rue de la Liberté, Tunis",
    warehouse: "Entrepôt Central",
    createdAt: "2024-01-01",
  },
  {
    name: "Caisse Sfax",
    address: "456 Avenue Bourguiba, Sfax",
    warehouse: "Entrepôt Sfax",
    createdAt: "2024-02-01",
  },
  {
    name: "Caisse Bizerte",
    address: "789 Rue de la République, Bizerte",
    warehouse: "Entrepôt Bizerte",
    createdAt: "2024-03-01",
  },
];

function CaisseList() {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3}>
          <Link to="/gestion-financiere/nouvelle-caisse">
            <SoftButton variant="gradient" color="info">
              + {t("Nouvelle caisse")}
            </SoftButton>
          </Link>

          <Grid container spacing={3} mt={1}>
            {caisseData.map((caisse, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card sx={{ display: "flex", boxShadow: 3, pt: 1 }}>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <SoftBox sx={{ width: "90%" }}>
                      <SoftTypography variant="h5" gutterBottom>
                        {caisse.name}
                      </SoftTypography>
                      <SoftTypography
                        variant="body2"
                        color="textSecondary"
                        truncate
                      >
                        {t("Adresse")}: {caisse.address}
                      </SoftTypography>

                      <SoftBox
                        display="flex"
                        alignItems={{ xxl: "center" }}
                        justifyContent="space-between"
                        flexDirection={{
                          xs: "column",
                          xxl: "row",
                        }}
                        mt={2}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <EventIcon
                            fontSize="small"
                            sx={{ mr: 1, color: "#007bff" }}
                          />
                          <SoftTypography variant="body2" color="textSecondary">
                            {t("Créé le")}:{" "}
                            {new Date(caisse.createdAt).toLocaleDateString()}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox display="flex" alignItems="center">
                          <WarehouseIcon
                            fontSize="small"
                            sx={{ mr: 1, color: "#28a745" }}
                          />
                          <SoftTypography variant="body2" color="textSecondary">
                            {t("Entrepôt")}: {caisse.warehouse}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <ActionCell />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default CaisseList;
