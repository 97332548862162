import React, { useState, useEffect } from "react";
import { Card, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "../components/ActionCell";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import WarehouseApi from "api/warehouse";
import { useTranslation } from "react-i18next";

function EntrepotsInfo() {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  // Fetch the warehouse list on component mount
  useEffect(() => {
    WarehouseApi.GetWarehouseList(token)
      .then((response) => {
        setWarehouses(response.data); // Update state with the API response data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching warehouse list:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [token]);

  // Columns for the DataTable
  const columns = [
    { Header: `${t("Nom")}`, accessor: "warehouse_name" }, // Warehouse name
    { Header: `${t("Ville")}`, accessor: "warehouse_city" }, // warehouse_city of the warehouse
    { Header: `${t("Adresse")}`, accessor: "location" }, // location of the warehouse
    { Header: `${t("Gestionnaires")}`, accessor: "warehouse_managers" }, // warehouse_managers of the warehouse
    { Header: `${t("action")}`, accessor: "action" }, // Actions (e.g., edit) for the warehouse
  ];

  // Transforming warehouse data into rows for the DataTable
  const rows = warehouses.map((warehouse) => ({
    warehouse_name: warehouse.warehouse_name,
    warehouse_city: warehouse.warehouse_city,
    location: warehouse.location,
    warehouse_managers: warehouse.warehouse_managers
      .map((manager) => manager.username) // Extracting usernames
      .join(", "), // Joining usernames with a comma
    action: <ActionCell ID={warehouse.id} />, // ActionCell component for edit actions
  }));

  if (loading) {
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={35}
      >
        <CircularProgress sx={{ color: "#1F51FF" }} />
      </SoftBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh" // Minimum height for the container
      >
        <Card sx={{ my: 5 }}>
          <SoftBox py={3} px={3}>
            {/* Button to navigate to the "Nouveau Entrepôt" page */}
            <Link to="/entreprise/nouveau-entrepot">
              <SoftButton variant="gradient" color="info">
                + {t("Nouveau Entrepôt")}
              </SoftButton>
            </Link>
          </SoftBox>

          <SoftBox>
            <DataTable
              table={{ columns, rows }} // Passing columns and rows to the DataTable
              entriesPerPage={false} // Disabling entries per page feature
              canSearch={rows.length > 0} // Enable search if there are rows
              columnMinWidth={70} // Minimum width for columns
            />
          </SoftBox>
        </Card>

        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default EntrepotsInfo;
