import React, { useState, useEffect } from "react";
import { Card, Grid, Typography, Box, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import FormField from "../ajouter-articles/components/FormField";
import { useLocation, useNavigate } from "react-router-dom";
import SupplierApi from "api/supplier";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";
import ProductsApi from "api/products";
import SoftSelect from "components/SoftSelect";

function EditSupplier() {
  const navigate = useNavigate();
  const location = useLocation();
  const { supplierID } = location.state || {};
  const { t, i18n } = useTranslation();

  const [supplierData, setSupplierData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    supplier_products: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [products, setProducts] = useState([]);
  // eslint-disable-next-line
  const [productsLoading, setProductsLoading] = useState(false);

  const isLargeScreen = window.innerWidth > 767;
  const rightPosition =
    i18n.language === "ar" && isLargeScreen
      ? window.innerWidth >= 2000
        ? "-326%"
        : window.innerWidth >= 1900
        ? "-330%"
        : window.innerWidth >= 1850
        ? "-339%"
        : window.innerWidth >= 1800
        ? "-339%"
        : window.innerWidth >= 1750
        ? "-343%"
        : window.innerWidth >= 1700
        ? "-349%"
        : window.innerWidth >= 1650
        ? "-352%"
        : window.innerWidth >= 1600
        ? "-360%"
        : window.innerWidth >= 1550
        ? "-364%"
        : window.innerWidth >= 1500
        ? "-371%"
        : window.innerWidth >= 1450
        ? "-293.5%"
        : window.innerWidth >= 1400
        ? "-296%"
        : window.innerWidth >= 1350
        ? "-299.5%"
        : window.innerWidth >= 1300
        ? "-302%"
        : window.innerWidth >= 1250
        ? "-306%"
        : window.innerWidth >= 1200
        ? "-310%"
        : window.innerWidth >= 1150
        ? "-257%"
        : window.innerWidth >= 1100
        ? "-257%"
        : window.innerWidth >= 1050
        ? "-259%"
        : window.innerWidth >= 1000
        ? "-261%"
        : window.innerWidth >= 950
        ? "-262%"
        : window.innerWidth >= 900
        ? "-264%"
        : window.innerWidth >= 850
        ? "-266%"
        : window.innerWidth >= 800
        ? "-268%"
        : "-270%"
      : "0";

  useEffect(() => {
    const fetchSuppliers = async () => {
      const token = localStorage.getItem("token");
      try {
        const supplierResponse = await SupplierApi.GetSupplierByID(
          token,
          supplierID
        );
        setSupplierData({
          ...supplierResponse.data,
          products: supplierResponse.data.products.map((product) => product.id),
        });
      } catch (error) {
        console.error("Failed to fetch supplier:", error);
      } finally {
        setLoading(false);
      }
    };

    if (supplierID) fetchSuppliers();
  }, [supplierID]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setProductsLoading(true); // Set loading to true when the request starts
    // Fetch products
    ProductsApi.GetProductsPrices(token)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => {
        setProductsLoading(false); // Set loading to false once the request is finished
      });
  }, []);

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!supplierData.name.trim())
      newErrors.name = t("Le nom est obligatoire.");
    if (!supplierData.phone.trim()) {
      newErrors.phone = t("Le téléphone est obligatoire.");
    } else if (!/^\d+$/.test(supplierData.phone)) {
      newErrors.phone = t(
        "Le téléphone doit contenir uniquement des chiffres."
      );
    }
    if (!supplierData.email.trim()) {
      newErrors.email = t("L'email est obligatoire.");
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(supplierData.email)) {
      newErrors.email = t("L'email n'est pas valide.");
    }
    if (!supplierData.address.trim()) {
      newErrors.address = t("L'adresse est obligatoire.");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate()) return;

    setSaving(true);
    const token = localStorage.getItem("token");
    try {
      const payload = {
        ...supplierData,
        supplier_products: supplierData.supplier_products,
      };
      console.log("payload", payload);
      await SupplierApi.EditSupplier(token, supplierID, payload);
      navigate("/gestion-de-stock/listes-des-fournisseurs");
    } catch (error) {
      console.error("Failed to update supplier:", error);
      alert("Erreur lors de la mise à jour du fournisseur.");
    } finally {
      setSaving(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setSupplierData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="70vh"
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3}>
          <Card>
            <SoftBox py={3} px={5}>
              <Typography variant="h6" fontWeight="bold" mb={2}>
                {t("Modifier les informations du fournisseur")}
              </Typography>
              <Grid container spacing={3} mb={2}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} md={2}>
                    <FormField
                      autoFocus
                      margin="dense"
                      label={t("Nom")}
                      fullWidth
                      value={supplierData.name}
                      onChange={(e) =>
                        handleFieldChange("name", e.target.value)
                      }
                      error={Boolean(errors.name)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.name}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormField
                      margin="dense"
                      label={t("Téléphone")}
                      fullWidth
                      value={supplierData.phone}
                      onChange={(e) =>
                        handleFieldChange("phone", e.target.value)
                      }
                      error={Boolean(errors.phone)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.phone}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <FormField
                      margin="dense"
                      label={t("Email")}
                      fullWidth
                      value={supplierData.email}
                      onChange={(e) =>
                        handleFieldChange("email", e.target.value)
                      }
                      error={Boolean(errors.email)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.email}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <FormField
                      label={t("Adresse")}
                      fullWidth
                      value={supplierData.address}
                      onChange={(e) =>
                        handleFieldChange("address", e.target.value)
                      }
                      error={Boolean(errors.address)}
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="error"
                    >
                      {errors.address}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SoftTypography variant="caption" fontWeight="bold">
                      {t("Produits")}
                    </SoftTypography>
                    <SoftSelect
                      isMulti
                      options={products.map((product) => ({
                        value: product.id,
                        label: product.product_name,
                      }))}
                      value={supplierData.supplier_products?.map((id) => {
                        const product = products.find((p) => p.id === id);
                        return product
                          ? { value: product.id, label: product.product_name }
                          : null;
                      })}
                      onChange={(selectedOptions) =>
                        handleFieldChange(
                          "supplier_products",
                          selectedOptions.map((opt) => opt.value)
                        )
                      }
                      placement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          right: rightPosition,
                        }),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <SoftButton
                  variant="contained"
                  color="info"
                  onClick={handleSave}
                  disabled={saving}
                >
                  {saving
                    ? `${t("Enregistrement")}...`
                    : t("Enregistrer les modifications")}
                </SoftButton>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default EditSupplier;
