import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ManagersApi from "api/managers";

function ManagersList() {
  const [managersList, setManagersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchor, setAnchor] = useState(null); // Anchor for menu
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [dateSortOrder, setDateSortOrder] = useState("asc");

  const { t } = useTranslation();

  const fetchManagresList = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await ManagersApi.GetManagersList(token);
      setManagersList(response.data);
    } catch (error) {
      console.error("Failed to fetch managersList:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchManagresList();
  }, []);

  const handleSortChange = (field) => {
    let newSortOrder;

    if (field === "firstname") {
      newSortOrder = nameSortOrder === "asc" ? "desc" : "asc";
      setNameSortOrder(newSortOrder);
      sortManagers(field, newSortOrder);
    } else if (field === "created_at") {
      newSortOrder = dateSortOrder === "asc" ? "desc" : "asc";
      setDateSortOrder(newSortOrder);
      sortManagers(field, newSortOrder);
    }

    setAnchor(null); // Close the menu after sorting
  };

  const sortManagers = (field, order) => {
    const sortedManagers = [...managersList].sort((a, b) => {
      const valueA =
        field === "created_at" ? new Date(a[field]) : a[field]?.toLowerCase();
      const valueB =
        field === "created_at" ? new Date(b[field]) : b[field]?.toLowerCase();

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    setManagersList(sortedManagers); // Update the managers list with sorted data
  };

  const columns = [
    { Header: t("Prénom"), accessor: "firstname" },
    { Header: t("Nom"), accessor: "lastname" },
    { Header: t("Téléphone"), accessor: "phone" },
    { Header: t("Poste"), accessor: "poste" },
  ];

  const rows = managersList.map((manager) => ({
    firstname: manager.firstname,
    lastname: manager.lastname,
    phone: manager.phone,
    poste: t("Manager d'entrepôt"),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={2}>
        <Link to="/gestion-des-ventes/nouveau-manager">
          <SoftButton variant="gradient" color="info" sx={{ marginRight: 2 }}>
            + {t("nouveau manager")}
          </SoftButton>
        </Link>
        <SoftButton
          variant="outlined"
          color="dark"
          onClick={(e) => setAnchor(e.currentTarget)}
        >
          {t("Trier par nom ou date")} (⬆⬇)
        </SoftButton>
        <Menu
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
        >
          <MenuItem onClick={() => handleSortChange("firstname")}>
            {t("Trier par Prénom")} ({nameSortOrder === "asc" ? "⬆" : "⬇"})
          </MenuItem>
          <MenuItem onClick={() => handleSortChange("created_at")}>
            {t("Trier par Date")} ({dateSortOrder === "asc" ? "⬆" : "⬇"})
          </MenuItem>
        </Menu>
      </SoftBox>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="80vh"
      >
        <Card sx={{ maxWidth: "70%", width: "100%" }}>
          <SoftBox p={3}>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                canSearch
              />
            )}
          </SoftBox>
        </Card>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ManagersList;
