import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";
import SupplierApi from "api/supplier";
import { CircularProgress } from "@mui/material";

function ActionCell({ supplierID }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleDelete = () => {
    setIsDeleting(true);
    const token = localStorage.getItem("token");

    SupplierApi.DeleteSupplier(token, supplierID)
      .then(() => {
        setOpen(false);
        navigate(0);
      })
      .catch((error) => {
        console.error("Error deleting supplier:", error); 
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <SoftBox display="flex" alignItems="center">
      {/* Edit Button */}
      <SoftTypography variant="body1" color="grey-400" sx={{ lineHeight: 0 }}>
        <Tooltip
          title={t("Modifier les informations du fournisseur")}
          placement="top"
        >
          <Link
            to={`/gestion-de-stock/modifer-informations-fournisseur`}
            state={{ supplierID }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Icon>edit</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>

      {/* Delete Button */}
      <SoftTypography variant="body1" color="error" sx={{ lineHeight: 0 }}>
        <Tooltip title={t("Supprimer le fournisseur")} placement="top">
          <button
            onClick={handleOpenDialog}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "inherit",
            }}
          >
            <Icon>delete</Icon>
          </button>
        </Tooltip>
      </SoftTypography>

      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Confirmer la suppression")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Êtes-vous sûr de vouloir supprimer ce fournisseur ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("Annuler")}
          </Button>
          <Button onClick={handleDelete} color="error" disabled={isDeleting}>
            {isDeleting ? (
              <CircularProgress size={24} sx={{ color: "#1F51FF" }} />
            ) : (
              t("Supprimer")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default ActionCell;
