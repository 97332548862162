import axios from "./index";

// ProductsApi class handles operations related to products
class ProductsApi {
  // Get Products
  /**
   * Fetches a paginated list of new products.
   * @param {string} token - The authentication token for the user.
   * @param {number} page - The page number for paginated results.
   * @returns {Promise} - The response containing the list of new products for the specified page.
   */
  static GetProducts = (token, page, supplier) => {
    const url = supplier
      ? `new_products?p=${page}&supplier_name=${supplier}`
      : `new_products?p=${page}`;
    return axios.get(`${base}/${url}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Search Products
  /**
   * Searches for products by name with pagination.
   * @param {string} token - The authentication token for the user.
   * @param {number} page - The page number for paginated results.
   * @param {string} query - The search query for product names.
   * @returns {Promise} - The response containing the search results for the specified query and page.
   */
  static SearchProductByName = (token, page, query) => {
    return axios.get(`${base}/products/?page=${page}&search=${query}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Product Prices
  /**
   * Fetches the list of product prices.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of product prices.
   */
  static GetProductsPrices = (token) => {
    return axios.get(`${base}/product_list_prices`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Products to Extract
  /**
   * Fetches the list of products to extract.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of products to extract.
   */
  static GetProductsToExtract = (token) => {
    return axios.get(`${base}/product_extracted`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // All Products
  /**
   * Fetches a list of all products.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the list of all products.
   */
  static GetAllProducts = (token, withoutStock) => {
    return axios.get(
      `${base}/product_list?without_initial_stock=${withoutStock || false}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  // Product by ID
  /**
   * Fetches details of a product by its ID.
   * @param {string|number} productId - The ID of the product to fetch.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the details of the specified product.
   */
  static GetProductById = (productId, token) => {
    return axios.get(`${base}/products/${productId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Promoted Products
  /**
   * Fetches details of a promoted product by its ID.
   * @param {string|number} productId - The ID of the promoted product to fetch.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the details of the specified promoted product.
   */
  static GetPromotedProductById = (productId, token) => {
    return axios.get(`${base}/promoted_products/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches a paginated list of promoted products.
   * @param {string} token - The authentication token for the user.
   * @param {number} page - The page number for paginated results.
   * @returns {Promise} - The response containing the list of promoted products for the specified page.
   */
  static GetPromotedProducts = (token, page) => {
    return axios.get(`${base}/promoted_products?p=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Create Products
  /**
   * Creates a new product.
   * @param {Object} productData - The data for the new product.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after creating the new product.
   */
  static CreateNewProduct = (productData, token) => {
    return axios.post(`${base}/products/`, productData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Creates a new promoted product.
   * @param {Object} promotionData - The data for the new promoted product.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after creating the new promoted product.
   */
  static CreateNewPromotedProduct = (promotionData, token) => {
    return axios.post(`${base}/promoted_products`, promotionData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Update Products
  /**
   * Updates an existing product by its ID.
   * @param {Object} productData - The updated data for the product.
   * @param {string|number} productId - The ID of the product to update.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after updating the product.
   */
  static UpdateProduct = (productData, productId, token) => {
    return axios.patch(`${base}/products/${productId}/`, productData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Updates an existing promoted product by its ID.
   * @param {Object} promotionData - The updated data for the promoted product.
   * @param {string|number} promotionId - The ID of the promoted product to update.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after updating the promoted product.
   */
  static UpdatePromotedProduct = (promotionData, promotionId, token) => {
    return axios.patch(
      `${base}/promoted_products/${promotionId}/`,
      promotionData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  // Delete Products
  /**
   * Deletes a product by its ID.
   * @param {string|number} productId - The ID of the product to delete.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after deleting the product.
   */
  static DeleteProduct = (productId, token) => {
    return axios.delete(`${base}/products/${productId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Deletes a promoted product by its ID.
   * @param {string|number} productId - The ID of the promoted product to delete.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after deleting the promoted product.
   */
  static DeletePromotedProduct = (productId, token) => {
    return axios.delete(`${base}/promoted_products/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  // Upload Products
  /**
   * Uploads a batch of products.
   * @param {Object} data - The data for the products to upload.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response after uploading the products.
   */
  static UploadProducts = (data, token) => {
    return axios.post(`${base}/upload_products`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the product-related API
let base = "product";

export default ProductsApi;
