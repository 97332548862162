import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ProductsApi from "api/products";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SoftTypography from "components/SoftTypography";
import FormField from "./components/FormField";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import StockApi from "api/stock";
import Footer from "examples/Footer";
import WarehouseApi from "api/warehouse";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SupplierApi from "api/supplier";

/**
 * `IncreaseStock` component provides a form to increase stock quantities for products.
 * Users can select a product and specify the quantity to increase.
 */
function IncreaseStock() {
  const navigate = useNavigate(); // Hook for navigation
  const { t } = useTranslation();

  // State for holding stock information (product ID and quantity)
  const [stockInfo, setStockInfo] = useState({
    product_id: "",
    quantity: null,
    price: null,
    date: "",
    unit_price: null,
    supplier: "",
    warehouse: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    WarehouseApi.GetWarehouseList(token)
      .then((response) => {
        setWarehouses(response.data);
        setLoadingWarehouses(false);
      })
      .catch((error) => {
        console.error("Error fetching warehouse list:", error);
        setLoadingWarehouses(false);
      });
  }, [token]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);
    ProductsApi.GetAllProducts(token)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Fetch suppliers from the API
  useEffect(() => {
    const fetchSuppliers = async () => {
      const token = localStorage.getItem("token");
      try {
        const supplierData = await SupplierApi.GetSuppliersList(token);
        setSuppliers(supplierData.data.results);
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  const validate = () => {
    let valid = true;
    const newErrors = {};

    if (!stockInfo.product_id) {
      newErrors.product_id = `${t("Le produit est requis !")}`;
      valid = false;
    }

    if (!stockInfo.quantity) {
      newErrors.quantity = `${t("La quantité est requise !")}`;
      valid = false;
    }

    if (!stockInfo.unit_price) {
      newErrors.unit_price = `${t("Le prix unitaire est requis !")}`;
      valid = false;
    }

    if (!stockInfo.warehouse) {
      newErrors.warehouse = `${t("L'entrepôt est requis !")}`;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleStockInfoChange = (newInfo) => {
    setErrors({});
    setStockInfo({
      ...stockInfo,
      ...newInfo,
    });
    if (errors[newInfo.name]) {
      setErrors({
        ...errors,
        [newInfo.name]: "",
      });
    }
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    if (!validate()) {
      return;
    }

    setLoadingSave(true);

    const payload = {
      new_stock_items: [
        {
          product_id: stockInfo.product_id.value,
          quantity: Number(stockInfo.quantity),
        },
      ],
    };

    StockApi.IncreaseStock(payload, token)
      .then((response) => {
        console.log("Stock créé avec succès:", response.data);
        navigate("/gestion-de-stock/situation-de-stock");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setLoadingSave(false);
      });
  };

  const handleCreateSupplier = () => {
    navigate("/gestion-de-stock/nouveau-fournisseur");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} mt={5}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Card elevation={3}>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom>
                    {t("Informations sur le stock")}
                  </SoftTypography>
                  <SoftBox mt={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Produit")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Produit")}
                            placeholder={t("Sélectionner un produit")}
                            value={stockInfo.product_id}
                            onChange={(newValue) =>
                              handleStockInfoChange({ product_id: newValue })
                            }
                            options={
                              loading
                                ? [
                                    {
                                      value: "",
                                      label: (
                                        <CircularProgress
                                          size={20}
                                          sx={{
                                            color: "#1F51FF",
                                            marginLeft: 7,
                                          }}
                                        />
                                      ),
                                    },
                                  ]
                                : products.length > 0
                                ? products.map((product) => ({
                                    value: product.id,
                                    label: product.product_name,
                                  }))
                                : [
                                    {
                                      value: "",
                                      label: `${t("Aucun produit disponible")}`,
                                    },
                                  ]
                            }
                            errorLabel={errors.product_id}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          type="number"
                          label={t("Valeur de stock")}
                          placeholder={t("Valeur de stock")}
                          value={stockInfo.quantity}
                          onChange={(e) => {
                            const value = Math.max(1, e.target.value);
                            handleStockInfoChange({
                              quantity: value,
                            });
                          }}
                          error={errors.quantity}
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <FormField
                          type="number"
                          label={t("Prix unitaire")}
                          placeholder={t("Prix unitaire")}
                          value={stockInfo.unit_price}
                          onChange={(e) => {
                            const value = Math.max(1, e.target.value);
                            handleStockInfoChange({
                              unit_price: value,
                            });
                          }}
                          error={errors.unit_price}
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <SoftTypography variant="caption" fontWeight="bold">
                          {t("Entrepôt")}
                        </SoftTypography>
                        <SoftSelect
                          placeholder={t("Sélectionner un entrepôt")}
                          value={stockInfo.warehouse}
                          onChange={(newValue) =>
                            handleStockInfoChange({ warehouse: newValue })
                          }
                          options={
                            loadingWarehouses
                              ? [
                                  {
                                    value: "",
                                    label: (
                                      <CircularProgress
                                        size={20}
                                        sx={{ color: "#1F51FF", marginLeft: 7 }}
                                      />
                                    ),
                                  },
                                ]
                              : warehouses.length > 0
                              ? warehouses.map((warehouse) => ({
                                  value: warehouse.id,
                                  label: warehouse.warehouse_name,
                                }))
                              : [
                                  {
                                    value: "",
                                    label: `${t("Aucun entrepôt disponible")}`,
                                  },
                                ]
                          }
                        />
                        <Typography variant="caption" color="error">
                          {errors.warehouse}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <FormField
                          fullWidth
                          label={t("Date")}
                          name="date"
                          type="date"
                          value={stockInfo.date}
                          onChange={(e) =>
                            handleStockInfoChange({ date: e.target.value })
                          }
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            min: new Date().toISOString().split("T")[0], // Désactive les dates passées
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <SoftBox>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {t("Fournisseur")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Fournisseur")}
                            placeholder={t("Sélectionner un fournisseur")}
                            value={stockInfo.supplier}
                            onChange={(newValue) =>
                              handleStockInfoChange({ supplier: newValue })
                            }
                            options={suppliers.map((supplier) => ({
                              value: supplier.id,
                              label: supplier.name,
                            }))}
                            placement="top"
                            isMulti
                          />
                          <SoftTypography
                            onClick={handleCreateSupplier}
                            variant="caption"
                            color="info"
                            fontWeight="bold"
                            sx={{ cursor: "pointer" }}
                          >
                            + {t("Créer un nouveau fournisseur")}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <SoftButton
                      disabled={loadingSave}
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                    >
                      {loadingSave ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        `${t("Enregistrer")}`
                      )}
                    </SoftButton>
                  </SoftBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default IncreaseStock;
