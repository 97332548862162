import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon, IconButton, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import flagUK from "../../assets/images/icons/flags/uk.png";
import flagFrance from "../../assets/images/icons/flags/france.png";
import flagTunisia from "../../assets/images/icons/flags/tunisia.png";
import SoftTypography from "components/SoftTypography";
import { light } from "@mui/material/styles/createPalette";

const LanguageSwitcher = ({ color, title, isDropdown = false }) => {
  const { i18n, t } = useTranslation();

  // Initialize selectedLanguage from localStorage or default to 'fr'
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "fr"
  );

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // Change language on initial load
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language); // Update selected language
    localStorage.setItem("language", language); // Save language in localStorage
    handleClose();
  };

  const getFlag = () => {
    return selectedLanguage === "en" ? flagUK : flagFrance;
  };

  const getLanguageLabel = () => {
    return selectedLanguage === "en" ? t("English") : t("French");
  };

  if (isDropdown) {
    return (
      <div>
        <IconButton
          size="small"
          onClick={handleClick}
          color={color || "inherit"}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={getFlag()}
            alt="Language"
            width="24"
            height="24"
            style={{
              marginRight: i18n.language === "ar" ? 0 : "8px",
              marginLeft: i18n.language === "ar" ? "8px" : 0,
            }}
          />
          {getLanguageLabel()}
          <ExpandMoreIcon style={{ marginLeft: "8px" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => changeLanguage("en")} title="English">
            <img
              src={flagUK}
              alt="English"
              width="24"
              height="24"
              style={{
                marginRight: i18n.language === "ar" ? 0 : "8px",
                marginLeft: i18n.language === "ar" ? "8px" : 0,
              }}
            />
            {t("English")}
          </MenuItem>
          <MenuItem onClick={() => changeLanguage("fr")} title="Français">
            <img
              src={flagFrance}
              alt="Français"
              width="24"
              height="24"
              style={{
                marginRight: i18n.language === "ar" ? 0 : "8px",
                marginLeft: i18n.language === "ar" ? "8px" : 0,
              }}
            />
            {t("French")}
          </MenuItem>
          <MenuItem onClick={() => changeLanguage("ar")} title="Arabe">
            <img
              src={flagTunisia}
              alt="Arabic"
              width="24"
              height="24"
              style={{
                marginRight: i18n.language === "ar" ? 0 : "8px",
                marginLeft: i18n.language === "ar" ? "8px" : 0,
              }}
            />
            {t("Arabic")}
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <div>
      <IconButton size="small" onClick={handleClick} color={color || "inherit"}>
        {title && (
          <SoftTypography
            variant="button"
            fontWeight="bold"
            color={color ? color : light ? "white" : "dark"}
            mx={1}
          >
            {t("Language")}
          </SoftTypography>
        )}
        <Icon>language_rounded</Icon>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => changeLanguage("en")} title="English">
          <img
            src={flagUK}
            alt="English"
            width="24"
            height="24"
            style={{
              marginRight: i18n.language === "ar" ? 0 : "8px",
              marginLeft: i18n.language === "ar" ? "8px" : 0,
            }}
          />
          {t("English")}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("fr")} title="Français">
          <img
            src={flagFrance}
            alt="Français"
            width="24"
            height="24"
            style={{
              marginRight: i18n.language === "ar" ? 0 : "8px",
              marginLeft: i18n.language === "ar" ? "8px" : 0,
            }}
          />
          {t("French")}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("ar")} title="Arabe">
          <img
            src={flagTunisia}
            alt="Arabe"
            width="24"
            height="24"
            style={{
              marginRight: i18n.language === "ar" ? 0 : "8px",
              marginLeft: i18n.language === "ar" ? "8px" : 0,
            }}
          />
          {t("Arabic")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
