import { useState, useEffect, useMemo } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";

import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import routes from "routes";
import {
  useSoftUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import brand from "assets/images/logo.png";
import { ProtectedRoute } from "ProtectedRoute";
import useNavRoutes from "nav.routes";
import i18n from "i18next"; // Assuming i18next is used for i18n
import { usePermissions } from "context/permissions-context/permissions.context";

export default function App() {
  const navRoutes = useNavRoutes();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const location = useLocation();
  const { userPermissions } = usePermissions();

  // Initialize cache for RTL support
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });
    setRtlCache(cacheRtl);
  }, []);

  // Set the language direction based on current language (LTR for English/French, RTL for Arabic)
  useEffect(() => {
    const currentDirection = i18n.language === "ar" ? "rtl" : "ltr";
    document.body.setAttribute("dir", currentDirection);
    // eslint-disable-next-line
  }, [i18n.language]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const isTokenExpired = () => {
    const token = localStorage.getItem("token");
    if (!token) return true;
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    const now = Date.now() / 1000;
    return tokenData.exp < now;
  };

  useEffect(() => {
    if (
      !isTokenExpired() &&
      userPermissions.length > 2 &&
      userPermissions.includes("manage_orders") &&
      !location.pathname.includes("gestion-des-commandes") &&
      !location.pathname.includes("gestion-des-promotions") &&
      !location.pathname.includes("paniers-ouverts") &&
      location.pathname !== "/gestion-des-ventes/nouveau-client" &&
      location.pathname !== "/gestion-des-ventes/etablir-une-facture" &&
      location.pathname !== "/gestion-de-stock/ajouter-articles" &&
      location.pathname !== "/gestion-de-stock/liste-des-articles" &&
      location.pathname !== "/gestion-de-stock/nouveau-stock" &&
      location.pathname !== "/gestion-de-stock/augmenter-le-stock"
    ) {
      // Redirect to /gestion-des-commandes/commandes-encours if conditions are met
      window.location.href = "/gestion-des-commandes/commandes-encours";
    } else if (
      !isTokenExpired() &&
      userPermissions.includes("dashboard_access") &&
      (location.pathname === "/authentication/connexion" ||
        location.pathname === "/home")
    ) {
      window.location.href = "/statistiques/statistiques-generales";
    } else if (
      !isTokenExpired() &&
      userPermissions.length > 0 &&
      !userPermissions.includes("dashboard_access") &&
      (location.pathname === "/authentication/connexion" ||
        location.pathname === "/home")
    ) {
      window.location.href = "/gestion-des-commandes/commandes-encours";
    } else if (
      isTokenExpired() &&
      location.pathname !== "/authentication/error/unauthorized" &&
      location.pathname !== "/authentication/connexion" &&
      location.pathname !== "/home" &&
      location.pathname !== "/privacy"
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.href = "/home";
    }
  }, [location.pathname, userPermissions]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        // Check if the route requires protection based on token and permissions
        if (route.protected) {
          // Use the ProtectedRoute component and pass the required permission
          return (
            <Route
              key={route.key}
              element={
                <ProtectedRoute requiredPermission={route.requiredPermission} />
              }
            >
              <Route path={route.route} element={route.component} />
            </Route>
          );
        }
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return i18n.language === "ar" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && pathname !== "/privacy" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Livrili Dashboard"
              routes={navRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route
            path="*"
            element={
              <Navigate
                to={
                  isTokenExpired()
                    ? "/home"
                    : "/statistiques/statistiques-generales"
                }
              />
            }
          />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && pathname !== "/privacy" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Livrili Dashboard"
            routes={navRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route
          path="*"
          element={
            <Navigate
              to={
                isTokenExpired()
                  ? "/home"
                  : "/statistiques/statistiques-generales"
              }
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
