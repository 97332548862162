import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Breadcrumbs from "examples/Breadcrumbs";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import Navbar from "../DropdownMenuNavbar";
import LanguageSwitcher from "components/LanguageSwitcher.js";
import { t } from "i18next";
import SoftButton from "components/SoftButton";

function DashboardNavbar({ absolute = false, light = false, isMini = false }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.business_role_id || null;

  let token = localStorage.getItem("token");

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/authentication/connexion");
  };

  const handleLogoutModalOpen = () => setLogoutModalOpen(true);
  const handleLogoutModalClose = () => setLogoutModalOpen(false);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const notifications = [
    { id: 1, message: "Ton demande de stock de Warehouse A a été confirmé." },
    { id: 2, message: "Ton demande de stock de Warehouse B est en attente." },
  ];

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
          <Icon
            fontSize="medium"
            sx={{ ...navbarDesktopMenu, ml: -5 }}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SoftBox>
        {(role === "warehouse_owner" || role === "admin") && (
          <SoftBox>
            <Navbar />
          </SoftBox>
        )}
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox color={light ? "white" : "inherit"}>
              {token ? (
                <>
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    onClick={handleLogoutModalOpen}
                  >
                    <SoftTypography
                      variant="button"
                      fontWeight="medium"
                      color={light ? "white" : "dark"}
                    >
                      {t("logout")}
                    </SoftTypography>
                  </IconButton>
                  <Dialog
                    open={logoutModalOpen}
                    onClose={handleLogoutModalClose}
                    maxWidth="xs"
                    fullWidth
                  >
                    <DialogTitle>
                      <SoftTypography variant="h6" fontWeight="medium">
                        {t("Confirm Logout")}
                      </SoftTypography>
                    </DialogTitle>
                    <DialogContent>
                      <SoftTypography variant="body2">
                        {t("Are you sure you want to logout?")}
                      </SoftTypography>
                    </DialogContent>
                    <DialogActions>
                      <SoftButton
                        onClick={handleLogoutModalClose}
                        variant="outlined"
                        color="info"
                      >
                        {t("Annuler")}
                      </SoftButton>
                      <SoftButton
                        onClick={handleLogout}
                        variant="contained"
                        color="error"
                      >
                        {t("logout")}
                      </SoftButton>
                    </DialogActions>
                  </Dialog>
                </>
              ) : (
                <Link to="/authentication/connexion">
                  <IconButton sx={navbarIconButton} size="small">
                    <Icon>account_circle</Icon>
                    <SoftTypography
                      variant="button"
                      fontWeight="medium"
                      color={light ? "white" : "dark"}
                    >
                      Sign in
                    </SoftTypography>
                  </IconButton>
                </Link>
              )}
              <IconButton
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  notifications
                </Icon>
              </IconButton>
              <Menu
                anchorEl={openMenu}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{ mt: 2 }}
              >
                {notifications.map((notification) => (
                  <MenuItem key={notification.id} onClick={handleCloseMenu}>
                    {notification.message}
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
            </SoftBox>
            <LanguageSwitcher />
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
