import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { CircularProgress, Snackbar, Alert, TextField } from "@mui/material";
import SoftSelect from "components/SoftSelect";
import CustomersApi from "api/customers";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import html2pdf from "html2pdf.js";

function GenerateRoussoucreRetenue() {
  const { t } = useTranslation();

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [taxData, setTaxData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found in localStorage.");
        setLoading(false);
        return;
      }

      try {
        const customersResponse = await CustomersApi.GetCustomersoExtract(
          token
        );
        setClients(customersResponse.data);
      } catch (error) {
        setError("Error fetching clients.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
  };

  const generatePDF = () => {
    const element = document.getElementById("pdfContent"); // Ensure this ID matches your HTML div
    if (!element) {
      console.error("PDF content element not found!");
      return;
    }

    const options = {
      margin: [0, 0, 0.2, 0.2],
      filename: "Retenue.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found in localStorage.");
      return;
    }

    if (!selectedClient || !startDate || !endDate) {
      setError("Please select a client and provide start and end dates.");
      return;
    }

    const params = {
      client_id: selectedClient.value,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const response = await CustomersApi.GetCustomersTax(token, params);
      setTaxData(response.data);
      setSuccess(true);
    } catch (error) {
      setError("Error fetching tax data.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} mt={10}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Card elevation={3}>
                <CardContent>
                  <SoftBox mt={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <SoftBox mb={1}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Client")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Client")}
                            placeholder={t("Sélectionner un client")}
                            value={selectedClient}
                            onChange={handleClientChange}
                            options={
                              loading
                                ? [
                                    {
                                      value: "",
                                      label: (
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minWidth="100%"
                                          minHeight="48px"
                                        >
                                          <CircularProgress
                                            size={20}
                                            sx={{
                                              color: "#1F51FF",
                                              marginLeft: 7,
                                            }}
                                          />
                                        </SoftBox>
                                      ),
                                    },
                                  ]
                                : clients.length > 0
                                ? clients.map((client) => ({
                                    value: client.id,
                                    label:
                                      client.username ||
                                      `${t("Client")} ${client.id}`,
                                  }))
                                : [
                                    {
                                      value: "",
                                      label: `${t("Aucun client disponible")}`,
                                    },
                                  ]
                            }
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox mb={1}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Date de début")}
                          </SoftTypography>
                          <TextField
                            fullWidth
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox mb={1}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Date de fin")}
                          </SoftTypography>
                          <TextField
                            fullWidth
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <SoftButton
                      onClick={handleSubmit}
                      variant="gradient"
                      color="dark"
                    >
                      {t("Enregistrer")}
                    </SoftButton>
                  </SoftBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>

        {/* Preview Section */}
        {taxData && (
          <>
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Card elevation={3}>
                  <CardContent>
                    <div id="pdfContent">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Arial, sans-serif",
                            padding: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <h2
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            REPUBLIQUE TUNISIENNE
                          </h2>
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            MINISTERE DU PLAN ET DES FINANCES
                          </h3>
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              width: "50%",
                              margin: "auto",
                            }}
                          >
                            DIRECTION GENERALE DU CONTROLE FISCAL
                          </h3>
                        </div>
                        <div
                          style={{
                            fontFamily: "Arial, sans-serif",
                            padding: "20px",
                          }}
                        >
                          <h2
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            CERTIFICAT DE RETENUE D'IMPOT
                          </h2>
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            SUR LE REVENU
                          </h3>
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            OU D'IMPOT SUR LES SOCIETES
                          </h3>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            marginBottom: "20px",
                          }}
                        >
                          Retenue effectuée le
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            marginBottom: "20px",
                            marginLeft: "2em",
                            marginRight: "6em",
                          }}
                        >
                          {taxData.end_date}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            margin: "0 5em",
                          }}
                        >
                          Facture : 0015
                        </p>
                      </div>
                      <div
                        style={{
                          marginTop: "-1em",
                          marginLeft: "15.2em",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          ou pendant(1)
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "Arial, sans-serif",
                          padding: "20px",
                          border: "1px solid",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: "12px",
                                marginBottom: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              A.- PERSONNE OU ORGANISME PAYEUR IDENTIFIANT
                            </p>
                          </div>
                          <div
                            style={{
                              width: "45%",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              IDENTIFIANT
                            </p>
                            <div
                              style={{
                                border: "1px solid #B8BBC2",
                                padding: "10px 30px 5px 20px",
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "12px",
                                  marginBottom: "5px",
                                }}
                              >
                                Matricule fiscal - Identifiant unique
                              </p>
                            </div>
                            <div
                              style={{
                                border: "1px solid #B8BBC2",
                                padding: "10px 30px 0 20px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "12px",
                                  marginBottom: "10px",
                                  textAlign: "center",
                                }}
                              >
                                1729582/P
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "2em",
                            marginTop: "10px",
                          }}
                        >
                          <p style={{ fontSize: "12px" }}>
                            Dénomination de la personne ou de l’organisme
                            payeur:
                          </p>
                          <p style={{ fontSize: "14px", fontWeight: "600" }}>
                            {taxData.client_name}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "2em",
                            marginTop: "10px",
                          }}
                        >
                          <p style={{ fontSize: "12px", marginBottom: "20px" }}>
                            Adresse:
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "20px" }}>
                            Rue Belgacem bazmi beb Bhar gabes 6000
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: "12px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          B.- RETENUES AFFECTUÉES SUR
                        </p>
                        <table
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            borderCollapse: "collapse",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                RETENUES AFFECTUÉES SUR :
                              </th>
                              <th
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                MONTANT BRUT
                              </th>
                              <th
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                RETENUE 1%
                              </th>
                              <th
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                MONTANT NET
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {taxData.orders_details.map((order, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderLeft: "1px solid #B8BBC2",
                                    borderRight: "1px solid #B8BBC2",
                                    padding: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  - Marché - taux général
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #B8BBC2",
                                    borderRight: "1px solid #B8BBC2",
                                    padding: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {order.order_taxed_sum} TND
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #B8BBC2",
                                    borderRight: "1px solid #B8BBC2",
                                    padding: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {order.order_taxed_sum * 0.01} TND
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #B8BBC2",
                                    borderRight: "1px solid #B8BBC2",
                                    padding: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {order.order_taxed_sum * 0.99} TND
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Total Général
                              </td>
                              <td
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {taxData.total_taxed_sum} TND
                              </td>
                              <td
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {taxData.total_taxed_sum * 0.01} TND
                              </td>
                              <td
                                style={{
                                  border: "1px solid #B8BBC2",
                                  padding: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {taxData.total_taxed_sum * 0.99} TND
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <p
                          style={{
                            fontSize: "12px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          C.- BENEFICIAIRE
                        </p>
                        <div className="flex justify-between">
                          <div className="w-1/2">
                            <p style={{ fontSize: "12px" }}>N° de la carte</p>
                            <p
                              style={{ fontSize: "12px", marginBottom: "5px" }}
                            >
                              ou de séjour pour les étrangers
                            </p>
                            <table>
                              <tr>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                                <td className="w-11 h-6 border"></td>
                              </tr>
                            </table>
                          </div>
                          <div className="1/2">
                            <p
                              style={{
                                fontSize: "14px",
                                marginBottom: "25px",
                                textAlign: "center",
                              }}
                            >
                              IDENTIFIANT
                            </p>
                            <table>
                              <tr>
                                <td className="text-sm font-semibold w-[15em] h-[2em] text-center py-3 border">
                                  Matricule fiscal - Identifiant unique
                                </td>
                              </tr>
                              <td className="w-[15em] h-[2em] text-center py-2 border"></td>
                            </table>
                          </div>
                        </div>
                        <div className="border-b w-full">
                          <p style={{ fontSize: "12px", marginBottom: "5px" }}>
                            Nom, prénoms ou raison sociale:{" "}
                            {taxData.client_name}
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "5px" }}>
                            Adresse professionnelle: Rue Belgacem bazmi beb Bhar
                            gabes 6000
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "20px" }}>
                            Adresse de résidence
                          </p>
                        </div>
                        <div className="py-5 flex justify-end">
                          <div className="w-[47%]">
                            <p
                              style={{ fontSize: "12px", marginBottom: "5px" }}
                            >
                              Je soussigné, certifie exacts les renseignements
                              figurants sur le présent certificat et m'expose
                              aux sanctions prévues par la loi pour toute
                              inexactitude
                            </p>
                            <div className="flex justify-around">
                              <p
                                style={{
                                  fontSize: "12px",
                                  marginBottom: "5px",
                                }}
                              >
                                TUNIS le
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  marginBottom: "5px",
                                }}
                              >
                                {taxData.end_date}
                              </p>
                            </div>

                            <p
                              style={{
                                fontSize: "12px",
                                paddingBottom: "5em",
                                textAlign: "center",
                              }}
                            >
                              Cachet et signature du payeur
                            </p>
                          </div>
                        </div>
                      </div>
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        (1) Le certificat est délivré à l’occasion de chaque
                        paiement. Toutefois, pour les opérations répétitives, le
                        certificat peut être délivré trimestriellement
                      </p>
                    </div>

                    <SoftBox mt={2} display="flex" justifyContent="flex-end">
                      <SoftButton
                        onClick={generatePDF}
                        variant="gradient"
                        color="dark"
                      >
                        {t("Télécharger PDF")}
                      </SoftButton>
                    </SoftBox>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
        <Footer />
      </SoftBox>
      {/* Snackbar for error and success messages */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          {t("Request submitted successfully!")}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default GenerateRoussoucreRetenue;
