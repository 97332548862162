import { createContext, useContext, useEffect, useState } from "react";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);

  // Permissions configuration
  const permissions = {
    warehouse_owner: ["dashboard_access"],
    admin: ["dashboard_access", "tech_support"],
    warehouse_manager: [
      "manage_orders",
      "create_invoice",
      "create_delivery_note",
      "create_new_stock",
      "increase_stock",
      "products_list",
      "add_new_product",
      "add_new_client",
      "open_shopping_carts",
      "manage_promotions",
      "manage_notifications",
    ],
    delivery_man: [],
    client: [],
  };

  // Effect to fetch and set user data from localStorage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const role = userData?.business_role_id || null;
    setUserRole(role);
    setUserPermissions(permissions[role] || []);
    // eslint-disable-next-line
  }, []);

  return (
    <PermissionsContext.Provider value={{ userRole, userPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
