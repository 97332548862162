import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import CategoriesApi from "api/categories";
import FormField from "../FormField";
import { useTranslation } from "react-i18next";
import SupplierApi from "api/supplier";

function ProductInfo({ onChange, defaultValues = {}, onValidation }) {
  const { t } = useTranslation();
  const [productName, setProductName] = useState(
    defaultValues.product_name || ""
  );
  const [referenceCode, setReferenceCode] = useState(
    defaultValues.reference_code || ""
  );
  const [description, setDescription] = useState(
    defaultValues.description || ""
  );
  const [category, setCategory] = useState(defaultValues.category_id || "");
  const [status, setStatus] = useState(defaultValues.status === "active");
  const [isTaxed, setIsTaxed] = useState(!!defaultValues.is_taxed);
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState(
    defaultValues.suppliers_details || []
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    handleGetCategories();
    fetchSuppliers();
    // eslint-disable-next-line
  }, []);

  const handleGetCategories = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Aucun jeton trouvé dans localStorage.");
      return;
    }

    CategoriesApi.GetCategories(token)
      .then((response) => {
        setCategories(response.data.results);
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      });
  }, []);

  const fetchSuppliers = async () => {
    const token = localStorage.getItem("token");
    try {
      const supplierData = await SupplierApi.GetSuppliersList(token);
      setSuppliers(supplierData.data.results);
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
    }
  };

  const validateFields = useCallback(() => {
    const newErrors = {};

    if (!productName.trim()) {
      newErrors.productName = t("Le nom du produit est obligatoire.");
    }
    if (!referenceCode.trim()) {
      newErrors.referenceCode = t("Le code de référence est obligatoire.");
    } else if (referenceCode.trim().length < 8) {
      newErrors.referenceCode = t(
        "Le code de référence doit contenir au moins 8 caractères."
      );
    }
    if (!category) {
      newErrors.category = t("La catégorie est obligatoire.");
    }
    if (!supplier.length) {
      newErrors.supplier = t("Veuillez sélectionner au moins un fournisseur.");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [productName, referenceCode, category, supplier, t]);

  const handleInputChange = useCallback(
    (field, value) => {
      switch (field) {
        case "productName":
          setProductName(value);
          break;
        case "referenceCode":
          setReferenceCode(value);
          break;
        case "description":
          setDescription(value);
          break;
        case "category":
          setCategory(value);
          break;
        case "supplier":
          setSupplier(value); // Update supplier state correctly
          break;
        default:
          break;
      }
      validateFields(); // Trigger validation after change
    },
    [validateFields]
  );

  useEffect(() => {
    if (onValidation) {
      onValidation(validateFields());
    }
    // eslint-disable-next-line
  }, [errors, onValidation]);

  useEffect(() => {
    onChange({
      productName,
      referenceCode,
      description,
      category,
      status: status ? "active" : "not active",
      isTaxed,
      supplier,
    });
  }, [
    productName,
    referenceCode,
    description,
    category,
    status,
    isTaxed,
    supplier,
    onChange,
  ]);

  return (
    <SoftBox>
      <SoftTypography variant="h5">
        {t("Informations sur le produit")}
      </SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label={t("Nom du produit")}
              placeholder={t("Nom du produit")}
              value={productName}
              onChange={(e) => handleInputChange("productName", e.target.value)}
              error={!!errors.productName}
            />
            {errors.productName && (
              <SoftTypography component="label" variant="caption" color="error">
                {errors.productName}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label={t("Code de référence")}
              placeholder="ex. 6191122554421"
              value={referenceCode}
              onChange={(e) =>
                handleInputChange("referenceCode", e.target.value)
              }
              error={!!errors.referenceCode}
            />
            {errors.referenceCode && (
              <SoftTypography component="label" variant="caption" color="error">
                {errors.referenceCode}
              </SoftTypography>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("Catégorie")}
              </SoftTypography>
              <SoftSelect
                value={category}
                onChange={(value) => handleInputChange("category", value)}
                options={categories.map((cat) => ({
                  value: cat.id.toString(),
                  label: cat.category_name,
                }))}
              />
              {errors.category && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  color="error"
                >
                  {errors.category}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            <SoftBox>
              <SoftTypography variant="caption" fontWeight="bold">
                {t("Fournisseur")}
              </SoftTypography>
              <SoftSelect
                isMulti
                value={supplier.map((sup) => ({
                  value: sup.id || sup.value,
                  label: sup.name || sup.label,
                }))}
                onChange={(selectedOptions) => {
                  const newSupplierArray = selectedOptions.map((option) => ({
                    id: option.value,
                    name: option.label,
                  }));
                  handleInputChange("supplier", newSupplierArray);
                }}
                options={suppliers.map((supplier) => ({
                  value: supplier.id,
                  label: supplier.name,
                }))}
              />
              {errors.supplier && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  color="error"
                >
                  {errors.supplier}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t("Description")} ({t("optionnel")})
              </SoftTypography>
            </SoftBox>
            <SoftEditor
              value={description}
              onChange={(value) => handleInputChange("description", value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mt={8}>
              <Grid
                item
                xs={12}
                sm={8}
                display="flex"
                justifyContent="space-around"
              >
                <SoftBox display="flex" alignItems="center" mb={2}>
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <SoftTypography variant="caption" fontWeight="bold" ml={1}>
                    {status ? t("Actif") : t("Inactif")}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" mb={2}>
                  <Switch
                    checked={isTaxed}
                    onChange={(e) => setIsTaxed(e.target.checked)}
                  />
                  <SoftTypography variant="caption" fontWeight="bold" ml={1}>
                    {isTaxed ? t("Taxé") : t("Non Taxé")}
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
