import React, { useEffect, useState } from "react";

// @mui/material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import OrdersApi from "api/orders";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { useTranslation } from "react-i18next";

// Modal Component
const ConfirmationModal = ({ open, onClose, onConfirm, action }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {action === "cancel"
          ? `${t("Annuler les commandes")}`
          : `${t("Confirmer les commandes")}`}
      </DialogTitle>
      <DialogContent>
        <SoftTypography>
          {action === "cancel"
            ? `${t(
                "Êtes-vous sûr de vouloir annuler les commandes sélectionnées?"
              )}`
            : `${t(
                "Êtes-vous sûr de vouloir confirmer les commandes sélectionnées?"
              )}`}
        </SoftTypography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("Non")}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {t("Oui")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function DraftOrders() {
  const { t } = useTranslation();

  const [menu, setMenu] = useState(null);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [anchor, setAnchor] = useState(null); // Anchor for menu
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [dateSortOrder, setDateSortOrder] = useState("asc");

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const handleGetOrders = (page) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setError("No token found in localStorage.");
      setLoading(false);
      return;
    }

    OrdersApi.GetDraftOrders(token, page)
      .then((response) => {
        if (response.data) {
          setOrders(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 10));
          localStorage.setItem("draftOrdersCount", response.data.count); // Save count to localStorage
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
        setError(error.response?.data?.msg || error.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetOrders(page);
  }, [page]);

  const handleSelectOrder = (id) => {
    setSelectedOrders((prevSelected) =>
      prevSelected?.includes(id)
        ? prevSelected.filter((orderId) => orderId !== id)
        : [...prevSelected, id]
    );
  };

  const handleOpenModal = (action) => {
    setModalAction(action);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalAction(null);
  };

  const handleConfirmAction = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      setLoading(false);
      return;
    }

    const actionApiCall =
      modalAction === "cancel"
        ? OrdersApi.CancelDraftOrders
        : OrdersApi.ConfirmDraftOrders;

    const promises = selectedOrders.map((orderId) =>
      actionApiCall(token, orderId)
    );

    Promise.all(promises)
      .then((responses) => {
        responses.forEach((response, index) => {
          console.log(
            `Order ${selectedOrders[index]} ${modalAction}ed:`,
            response.data
          );
        });
        handleGetOrders();
      })
      .catch((error) => {
        console.error(
          `Error ${modalAction}ing orders:`,
          error.response?.data?.msg || error.message
        );
      })
      .finally(() => {
        setSelectedOrders([]);
        handleCloseModal();
        setLoading(false);
      });
  };

  const handleDataUpdate = React.useCallback(() => {
    handleGetOrders(page);
    setSelectedOrders([]);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (field) => {
    let newSortOrder;

    if (field === "username") {
      newSortOrder = nameSortOrder === "asc" ? "desc" : "asc";
      setNameSortOrder(newSortOrder);
      sortOrders("customer", newSortOrder);
    } else if (field === "created_at") {
      newSortOrder = dateSortOrder === "asc" ? "desc" : "asc";
      setDateSortOrder(newSortOrder);
      sortOrders("created_at", newSortOrder);
    }

    setAnchor(null); // Close the menu after sorting
  };

  const sortOrders = (field, order) => {
    const sortedOrders = [...orders].sort((a, b) => {
      const valueA =
        field === "created_at" ? new Date(a[field]) : a[field].toLowerCase();
      const valueB =
        field === "created_at" ? new Date(b[field]) : b[field].toLowerCase();

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    setOrders(sortedOrders); // Update the orders with the sorted array
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t("Client")}`,
        accessor: "customer",
        width: 70,
        Cell: ({ cell }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={selectedOrders.includes(cell.row.original.id)}
              onChange={() => handleSelectOrder(cell.row.original.id)}
            />
            <span style={{ marginLeft: 8 }}>{cell.row.original.customer}</span>
          </div>
        ),
      },
      // { Header: "Type de commande", accessor: "doc_type" },
      { Header: `${t("Méthode de paiement")}`, accessor: "payment_methode" },
      { Header: `${t("Prix total")}`, accessor: "total_price" },
      { Header: `${t("Date de création")}`, accessor: "created_at" },
      {
        Header: `${t("action")}`,
        accessor: "action",
        Cell: ({ row }) => (
          <ActionCell
            orderId={row.original.id}
            onDataUpdate={handleDataUpdate}
          />
        ),
      },
    ],
    [selectedOrders, handleDataUpdate, t]
  );

  const rows = React.useMemo(
    () =>
      orders.map((order) => ({
        id: order.id,
        customer: order.customer,
        // doc_type: order.doc_type,
        payment_methode: order.payment_methode,
        total_price: `${order.total_price} TND`,
        created_at: new Date(order.created_at).toLocaleString(),
      })),
    [orders]
  );

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() =>
          selectedOrders.length > 0 ? handleOpenModal("confirm") : null
        }
      >
        <SoftTypography variant="button" fontWeight="regular">
          {t("Confirmer les commandes sélectionnées")}
        </SoftTypography>
      </MenuItem>
      <MenuItem
        onClick={() =>
          selectedOrders.length > 0 ? handleOpenModal("cancel") : null
        }
      >
        <SoftTypography variant="button" color="error" fontWeight="regular">
          {t("Annuler les commandes sélectionnées")}
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={3}>
          <SoftTypography color="error">{error}</SoftTypography>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={2}
          >
            <SoftButton
              variant={menu ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
              disabled={orders.length === 0}
            >
              {t("État de la commande")}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton>
            {renderMenu}
            <SoftButton
              variant="outlined"
              color="dark"
              disabled={orders.length === 0}
              onClick={(e) => setAnchor(e.currentTarget)}
            >
              {t("Trier par nom ou date")} (⬆⬇)
            </SoftButton>
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => handleSortChange("username")}>
                {t("Trier par Nom")} ({nameSortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
              <MenuItem onClick={() => handleSortChange("created_at")}>
                {t("Trier par Date")} ({dateSortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
            </Menu>

            {/* <SoftBox ml={1}>
            <SoftButton variant="outlined" color="dark">
              <Icon>description</Icon>
              &nbsp;Exporter CSV
            </SoftButton>
          </SoftBox> */}
          </SoftBox>
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  canSearch={orders.length > 0}
                  columnMinWidth={70}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 100,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmAction}
        action={modalAction}
      />
    </DashboardLayout>
  );
}

export default DraftOrders;
