import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";

const SupplierCell = ({ value }) => (
  <span style={{ cursor: "default" }}>
    {value.slice(0, 1).join(", ")}
    {value.length > 1 && ` +${value.length - 1} autres`}

    <Tooltip
      title={
        <ul style={{ margin: 0, padding: 5, listStyle: "none" }}>
          {value.map((supplier, index) => (
            <li key={index} style={{ margin: 0, padding: 2, fontSize: "1rem" }}>
              {supplier}
            </li>
          ))}
        </ul>
      }
      arrow
      placement="auto"
    >
      {value.length > 1 && (
        <IconButton
          size="small"
          style={{ marginLeft: 10, padding: 0, color: "blue" }}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  </span>
  );

export default SupplierCell;
