import React, { useEffect, useState } from "react";

// @mui/material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import OrdersApi from "api/orders";
import ActionCell from "./components/ActionCell";
import { CircularProgress, Menu, MenuItem, Pagination, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import SoftButton from "components/SoftButton";

function CancelledOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [anchor, setAnchor] = useState(null); // Anchor for menu
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [dateSortOrder, setDateSortOrder] = useState("asc");

  const { t } = useTranslation();

  const handleGetOrders = (page) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setError("No token found in localStorage.");
      setLoading(false);
      return;
    }

    OrdersApi.GetCancelledOrders(token, page)
      .then((response) => {
        if (response.data) {
          setOrders(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 10));
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
        setError(error.response?.data?.msg || error.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetOrders(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (field) => {
    let newSortOrder;

    if (field === "username") {
      newSortOrder = nameSortOrder === "asc" ? "desc" : "asc";
      setNameSortOrder(newSortOrder);
      sortOrders("customer", newSortOrder);
    } else if (field === "created_at") {
      newSortOrder = dateSortOrder === "asc" ? "desc" : "asc";
      setDateSortOrder(newSortOrder);
      sortOrders("created_at", newSortOrder);
    }

    setAnchor(null); // Close the menu after sorting
  };

  const sortOrders = (field, order) => {
    const sortedOrders = [...orders].sort((a, b) => {
      const valueA =
        field === "created_at" ? new Date(a[field]) : a[field].toLowerCase();
      const valueB =
        field === "created_at" ? new Date(b[field]) : b[field].toLowerCase();

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    setOrders(sortedOrders); // Update the orders with the sorted array
  };

  const columns = React.useMemo(
    () => [
      { Header: `${t("Client")}`, accessor: "customer" },
      // { Header: "Type de commande", accessor: "doc_type" },
      { Header: `${t("Méthode de paiement")}`, accessor: "payment_methode" },
      { Header: `${t("Prix total")}`, accessor: "total_price" },
      { Header: `${t("Date de création")}`, accessor: "created_at" },
      { Header: `${t("action")}`, accessor: "action" },
    ],
    [t]
  );

  const rows = React.useMemo(
    () =>
      orders.map((order) => ({
        customer: order.customer,
        // doc_type: order.doc_type,
        payment_methode: order.payment_methode,
        total_price: `${order.total_price} TND`,
        created_at: new Date(order.created_at).toLocaleString(),
        action: <ActionCell orderId={order.id} />,
      })),
    [orders]
  );

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={3}>
          <SoftTypography color="error">{error}</SoftTypography>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={2}
          >
            <SoftButton
              variant="outlined"
              color="dark"
              disabled={orders.length === 0}
              onClick={(e) => setAnchor(e.currentTarget)}
            >
              {t("Trier par nom ou date")} (⬆⬇)
            </SoftButton>
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => handleSortChange("username")}>
                {t("Trier par Nom")} ({nameSortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
              <MenuItem onClick={() => handleSortChange("created_at")}>
                {t("Trier par Date")} ({dateSortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
            </Menu>
          </SoftBox>

          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  canSearch={orders.length > 0}
                  columnMinWidth={70}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 100,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default CancelledOrders;
