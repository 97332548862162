import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useParams } from "react-router-dom";
import StockApi from "api/stock";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * `TransactionHistory` component displays the transaction history for a specific product.
 * It fetches transaction data based on the product ID from the URL parameters.
 */
function TransactionHistory() {
  const { t } = useTranslation();

  // Extracts the product ID from the URL parameters
  const { id } = useParams();

  // State for holding transaction data and loading state
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * Fetches transaction data by product ID and updates state.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.log("No token found in localStorage.");
        return;
      }

      try {
        const response = await StockApi.GetTransactionHistoryByProduct(
          id,
          token
        );
        if (response.data) {
          setData(response.data);
        } else {
          console.log("No data found.");
        }
      } catch (error) {
        console.log(error.response?.data?.msg || error.message);
      }
    } catch (error) {
      console.log("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  // Effect hook to fetch transaction data on component mount or ID change
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  // Display loading spinner while data is being fetched
  if (loading)
    return (
      <Card>
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      </Card>
    );

  // Destructure the fetched data
  const {
    res: transactions,
    quantity_in_stock,
    value_in_stock,
    min_stock,
  } = data;

  // Calculate totals from transaction data
  const totalEntreeQuantite = transactions?.reduce(
    (sum, row) => sum + row.entered_quantity,
    0
  );
  const totalSortieQuantite = transactions?.reduce(
    (sum, row) => sum + row.sorted_quantity,
    0
  );
  const totalValeurEntree = transactions?.reduce(
    (sum, row) => sum + row.entered_value,
    0
  );
  const totalValeurSortie = transactions?.reduce(
    (sum, row) => sum + row.sorted_value,
    0
  );
  const totalTotal = transactions?.reduce((sum, row) => sum + row.total, 0);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <SoftBox xs={12} display="flex" justifyContent="space-between">
                  <SoftTypography
                    variant="h4"
                    p={3}
                    ml="40%"
                    fontWeight="medium"
                  >
                    {t("Stock Article")}
                  </SoftTypography>
                  <SoftTypography
                    textAlign="right"
                    px={6}
                    py={3}
                    variant="h6"
                    fontWeight="medium"
                  >
                    {new Date().toLocaleDateString()}
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid
                    container
                    xs={12}
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={5}>
                      <SoftBox display="flex" flexDirection="column" pl={23}>
                        <SoftBox display="flex" gap={6}>
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {t("Stock Min")}
                          </SoftTypography>
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {min_stock || ""}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" gap={6}>
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {t("Stock Max")}
                          </SoftTypography>
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {""}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={2}>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox display="flex" gap={2} alignItems="center">
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {t("Quantité en stock")}
                          </SoftTypography>
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {quantity_in_stock}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" gap={2} alignItems="center">
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {t("Valeur en stock")}
                          </SoftTypography>
                          <SoftTypography
                            textAlign="left"
                            variant="h6"
                            fontWeight="medium"
                          >
                            {value_in_stock}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox p={3}>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Date
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Type Operation")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Description
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("P.U")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Entrée Quantité")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Sortie Quantité")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Valeur Entrée")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Valeur Sortie")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions?.map((row, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.date.split(",")[0]}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.operation_type}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.description}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.pu}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.entered_quantity}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.sorted_quantity}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.entered_value}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.sorted_value}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.total}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Total Mouvements
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalEntreeQuantite}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalSortieQuantite}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalValeurEntree}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalValeurSortie}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalTotal}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default TransactionHistory;
