import Reports from "layouts/rapports/rapports-generales";
import NewUser from "layouts/gestion-des-ventes/nouveau-client";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/gestion-des-promotions/liste-des-notifications";
import RTL from "layouts/pages/rtl";
import EditProduct from "layouts/gestion-de-stock/modifier-l-article";
import ProductPage from "layouts/gestion-de-stock/details-de-l-article";
import ProductsList from "layouts/gestion-de-stock/liste-des-articles";
import Stock from "layouts/gestion-de-stock/situation-de-stock";
import OrderDetails from "layouts/gestion-des-commandes/details-de-commande";
import SignInBasic from "layouts/authentication/connexion";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignOut from "layouts/authentication/sign-out";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";
import DraftOrders from "layouts/gestion-des-commandes/commandes-encours";
import Promotions from "layouts/gestion-des-promotions/liste-des-promotions";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import ConfirmedOrders from "layouts/gestion-des-commandes/commandes-confirmees";
import DeliveredOrders from "layouts/gestion-des-commandes/commandes-livrees";
import CancelledOrders from "layouts/gestion-des-commandes/commandes-annulees";
import OnWayOrders from "layouts/gestion-des-commandes/commandes-expediees";
import TransactionHistory from "layouts/gestion-de-stock/historique-de-transactions";
import UsersList from "layouts/gestion-des-ventes/liste-des-clients";
import EditUser from "layouts/gestion-des-ventes/modifer-le-client";
import NewPromotion from "layouts/gestion-des-promotions/nouvelle-promotion";
import NewNotification from "layouts/gestion-des-promotions/nouvelle-notification";
import EditPromotion from "layouts/gestion-des-promotions/modifier-la-promotion";
import ShoppingItems from "layouts/paniers-ouverts/paniers-en-cours";
import ShoppingItemsDetails from "layouts/paniers-ouverts/details-de-panier";
import NewStock from "layouts/gestion-de-stock/nouveau-stock";
import NewOrder from "layouts/gestion-des-commandes/ajouter-commande";
import BalanceClient from "layouts/gestion-des-ventes/balance-client";
import CompteClient from "layouts/gestion-des-ventes/compte-client";
import IncreaseStock from "layouts/gestion-de-stock/augmenter-le-stock";
import UserHistory from "layouts/gestion-des-ventes/historique-de-client";
import Privacy from "layouts/privacy";
import CreateInvoice from "layouts/gestion-des-ventes/etablir-une-facture";
import InvoiceStatus from "layouts/gestion-des-ventes/etat-de-facture";
import InvoiceDetails from "layouts/gestion-des-ventes/details-de-facture";
import Default from "layouts/statistiques/statistiques-generales";
import HomePage from "layouts/home";
import EntrepriseInfo from "layouts/entreprise/informations-entreprise";
import { Icon } from "@mui/material";
import ModifierEntrepriseInfo from "layouts/entreprise/modifer-informations-entreprise";
import EntrepotsInfo from "layouts/entreprise/informations-entrepots";
import ModifierEntrepotsInfo from "layouts/entreprise/modifer-informations-entrepots";
import NewProduct from "layouts/gestion-de-stock/ajouter-articles";
import FinancialDocument from "examples/Icons/FinancialDocument";
import Banque from "layouts/gestion-financiere/banque-details";
import Caisse from "layouts/gestion-financiere/caisse-details/";
import BankList from "layouts/gestion-financiere/liste-des-banques";
import CreateBank from "layouts/gestion-financiere/nouvelle-banque";
import CaisseList from "layouts/gestion-financiere/liste-des-caisses";
import CreateCaisse from "layouts/gestion-financiere/nouvelle-caisse";
import EditBank from "layouts/gestion-financiere/modifier-banque";
import EditCaisse from "layouts/gestion-financiere/modifier-caisse";
import NewWarehouse from "layouts/entreprise/nouveau-entrepot";
import StockOfAllWarehouses from "layouts/gestion-de-stock/stock-de-tous-les-entrepots";
import StockRequest from "layouts/gestion-de-stock/demande-de-stock";
import MesDemandesDeStock from "layouts/gestion-de-stock/mes-demandes-de-stock";
import SuppliersList from "layouts/gestion-de-stock/listes-des-fournisseurs";
import NewSupplier from "layouts/gestion-de-stock/nouveau-fournisseur";
import EditSupplier from "layouts/gestion-de-stock/modifer-informations-fournisseur";
import DeliveryNoteDetails from "layouts/details-de-bon-de-livraison";
import NewManager from "layouts/gestion-des-ventes/managers/nouveau-manager";
import ManagersList from "layouts/gestion-des-ventes/managers/liste-des-managers";
import DeliveryNoteStatus from "layouts/gestion-des-ventes/etat-de-bon-de-livraison";
import UnauthorizedPage from "layouts/authentication/error/unauthorized";
import GenerateRoussoucreRetenue from "layouts/gestion-financiere/Generation-de-certificat-retenue-a-la-ressource";

const routes = [
  {
    type: "collapse",
    name: "Entreprise",
    key: "entreprise",
    icon: <Icon sx={{ color: "inherit" }}>business</Icon>,
    collapse: [
      {
        name: "Informations sur l'entreprise",
        key: "informations-entreprise",
        route: "/entreprise/informations-entreprise",
        component: <EntrepriseInfo />,
        protected: true,
      },
      {
        name: "Informations sur les entrepôts",
        key: "informations-entrepots",
        route: "/entreprise/informations-entrepots",
        component: <EntrepotsInfo />,
        protected: true,
      },
      {
        name: "Modifier les informations sur l'entreprise",
        key: "modifer-informations-entreprise",
        route: "/entreprise/modifer-informations-entreprise",
        component: <ModifierEntrepriseInfo />,
        protected: true,
      },
      {
        name: "Nouveau entrepôt",
        key: "nouveau-entrepot",
        route: "/entreprise/nouveau-entrepot",
        component: <NewWarehouse />,
        protected: true,
      },
      {
        name: "Modifier les informations des entrepots",
        key: "modifer-informations-entrepots",
        route: "/entreprise/modifer-informations-entrepots",
        component: <ModifierEntrepotsInfo />,
        protected: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "Statistiques",
    key: "statistiques",
    icon: <Shop size="12px" />,
    collapse: [
      // {
      //   name: "Rapports des produits",
      //   key: "overview",
      //   route: "/overview",
      //   component: <Overview />,
      //   protected: true,
      // },
      {
        name: "Statistiques générales",
        key: "statistiques-generales",
        route: "/statistiques/statistiques-generales",
        component: <Default />,
        protected: true,
      },
      // {
      //   name: "Rapports des utilisateurs",
      //   key: "reports",
      //   route: "/rapports",
      //   component: <Reports />,
      //   protected: true,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Rapports",
    key: "rapports",
    icon: <Shop size="12px" />,
    collapse: [
      // {
      //   name: "Rapports des articles",
      //   key: "overview",
      //   route: "/overview",
      //   component: <Overview />,
      //   protected: true,
      // },
      {
        name: "Rapports générales",
        key: "rapports-generales",
        route: "/rapports/rapports-generales",
        component: <Reports />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Gestion des commades",
    key: "gestion-des-commandes",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Ajouter commande",
        key: "ajouter-commande",
        route: "/gestion-des-commandes/ajouter-commande",
        component: <NewOrder />,
        protected: true,
      },
      {
        name: "Commandes encours",
        key: "commandes-encours",
        route: "/gestion-des-commandes/commandes-encours",
        component: <DraftOrders />,
        protected: true,
      },
      {
        name: "Commandes confirmées",
        key: "commandes-confirmees",
        route: "/gestion-des-commandes/commandes-confirmees",
        component: <ConfirmedOrders />,
        protected: true,
      },
      {
        name: "Commandes expediées",
        key: "commandes-expediees",
        route: "/gestion-des-commandes/commandes-expediees",
        component: <OnWayOrders />,
        protected: true,
      },
      {
        name: "Commandes Livrées",
        key: "commandes-livrees",
        route: "/gestion-des-commandes/commandes-livrees",
        component: <DeliveredOrders />,
        protected: true,
      },
      {
        name: "Commandes annulées",
        key: "commandes-annulees",
        route: "/gestion-des-commandes/commandes-annulees",
        component: <CancelledOrders />,
        protected: true,
      },
      {
        name: "Détails de commande",
        key: "details-de-commande",
        route: "/gestion-des-commandes/details-de-commande/:orderId",
        component: <OrderDetails />,
        protected: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "Gestion des ventes",
    key: "gestion-des-ventes",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "Ajouter client",
        key: "nouveau-client",
        route: "/gestion-des-ventes/nouveau-client",
        component: <NewUser />,
        protected: true,
      },
      {
        name: "Liste des clients",
        key: "liste-des-clients",
        route: "/gestion-des-ventes/liste-des-clients",
        component: <UsersList />,
        protected: true,
      },
      {
        name: "Ajouter manager",
        key: "nouveau-manager",
        route: "/gestion-des-ventes/nouveau-manager",
        component: <NewManager />,
        protected: true,
      },
      {
        name: "Liste des managers",
        key: "liste-des-managers",
        route: "/gestion-des-ventes/liste-des-managers",
        component: <ManagersList />,
        protected: true,
      },
      {
        name: "Balance clients",
        key: "balance-clients",
        route: "/gestion-des-ventes/balance-clients",
        component: <BalanceClient />,
        protected: true,
      },
      {
        name: "Compte client",
        key: "compte-client",
        route: "/gestion-des-ventes/compte-client",
        component: <CompteClient />,
        protected: true,
      },
      {
        name: "Etablir une facture",
        key: "etablir-une-facture",
        route: "/gestion-des-ventes/etablir-une-facture",
        component: <CreateInvoice />,
        protected: true,
      },
      {
        name: "Etat de facture",
        key: "etat-de-facture",
        route: "/gestion-des-ventes/etat-de-facture",
        component: <InvoiceStatus />,
        protected: true,
      },
      {
        name: "Edit User",
        key: "modifer-le-client",
        route: "/gestion-des-ventes/modifer-le-client/:userId",
        component: <EditUser />,
        protected: true,
      },
      {
        name: "Client history",
        key: "client-history",
        route: "/gestion-des-ventes/historique-de-client/:userId",
        component: <UserHistory />,
        protected: true,
      },
      {
        name: "Details de facture",
        key: "details-de-facture",
        route: "/gestion-des-ventes/details-de-facture/:id",
        component: <InvoiceDetails />,
        protected: true,
      },
      {
        name: "Details de bon de livraison",
        key: "details-de-bon-de-livraison",
        route: "/details-de-bon-de-livraison/:id",
        component: <DeliveryNoteDetails />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Gestion des stocks",
    key: "gestion-de-stock",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Ajouter articles",
        key: "ajouter-articles",
        route: "/gestion-de-stock/ajouter-articles",
        component: <NewProduct />,
        protected: true,
      },
      {
        name: "Liste des articles",
        key: "liste-des-articles",
        route: "/gestion-de-stock/liste-des-articles",
        component: <ProductsList />,
        protected: true,
      },
      {
        name: "Nouveau stock",
        key: "nouveau-stock",
        route: "/gestion-de-stock/nouveau-stock",
        component: <NewStock />,
        protected: true,
      },
      {
        name: "Augmenter le stock",
        key: "augmenter-le-stock",
        route: "/gestion-de-stock/augmenter-le-stock",
        component: <IncreaseStock />,
        protected: true,
      },
      {
        name: "Nouveau fournisseur",
        key: "nouveau-fournisseur",
        route: "/gestion-de-stock/nouveau-fournisseur",
        component: <NewSupplier />,
        protected: true,
      },
      {
        name: "Modifier les informations du fournisseur",
        key: "modifer-informations-fournisseur",
        route: "/gestion-de-stock/modifer-informations-fournisseur",
        component: <EditSupplier />,
        protected: true,
      },
      {
        name: "Liste des fournisseurs",
        key: "listes-des-fournisseurs",
        route: "/gestion-de-stock/listes-des-fournisseurs",
        component: <SuppliersList />,
        protected: true,
      },
      {
        name: "Situation de stock",
        key: "situation-de-stock",
        route: "/gestion-de-stock/situation-de-stock",
        component: <Stock />,
        protected: true,
      },
      {
        name: "Situation de stock de tous les entrepôts",
        key: "stock-de-tous-les-entrepots",
        route: "/gestion-de-stock/stock-de-tous-les-entrepots",
        component: <StockOfAllWarehouses />,
        protected: true,
      },
      {
        name: "Demande de stock",
        key: "demande-de-stock",
        route: "/gestion-de-stock/demande-de-stock",
        component: <StockRequest />,
        protected: true,
      },
      {
        name: "Mes demandes de stock",
        key: "mes-demandes-de-stock",
        route: "/gestion-de-stock/mes-demandes-de-stock",
        component: <MesDemandesDeStock />,
        protected: true,
      },
      {
        name: "Modifier l'article",
        key: "modifier-l-article",
        route: "/gestion-de-stock/modifier-l-article/:productId",
        component: <EditProduct />,
        protected: true,
      },
      {
        name: "Details de l'article",
        key: "details-de-l-article",
        route: "/gestion-de-stock/details-de-l-article/:productId",
        component: <ProductPage />,
        protected: true,
      },
      {
        name: "Historique de transactions",
        key: "historique-de-transactions",
        route: "/gestion-de-stock/historique-de-transactions/:id",
        component: <TransactionHistory />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Gestion des promotions",
    key: "gestion-des-promotions",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Nouvelle promotion",
        key: "nouvelle-promotion",
        route: "/gestion-des-promotions/nouvelle-promotion",
        component: <NewPromotion />,
        protected: true,
      },
      {
        name: "Modifier la promotion",
        key: "modifier-la-promotion",
        route: "/gestion-des-promotions/modifier-la-promotion/:promotionId",
        component: <EditPromotion />,
        protected: true,
      },
      {
        name: "Liste des promotions",
        key: "liste-des-promotions",
        route: "/gestion-des-promotions/liste-des-promotions",
        component: <Promotions />,
        protected: true,
      },
      {
        name: "Nouvelle notification",
        key: "nouvelle-notification",
        route: "/gestion-des-promotions/nouvelle-notification",
        component: <NewNotification />,
        protected: true,
      },
      {
        name: "Liste des notfications",
        key: "liste-des-notifications",
        route: "/gestion-des-promotions/liste-des-notifications",
        component: <Notifications />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Paniers ouverts",
    key: "paniers-ouverts",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Panier d'achat en cours",
        key: "paniers-en-cours",
        route: "/paniers-ouverts/paniers-en-cours",
        component: <ShoppingItems />,
        protected: true,
      },
      {
        name: "Shopping items details",
        key: "shopping-items-details",
        route: "/paniers-ouverts/details-de-panier/:client_id/:page",
        component: <ShoppingItemsDetails />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Gestion financière",
    key: "gestion-financiere",
    icon: <FinancialDocument size="12px" />,
    collapse: [
      {
        name: "Liste des banques",
        key: "liste-des-banques",
        route: "/gestion-financiere/liste-des-banques",
        component: <BankList />,
        protected: true,
      },
      {
        name: "Banque",
        key: "banque-details",
        route: "/gestion-financiere/banque-details",
        component: <Banque />,
        protected: true,
      },
      {
        name: "Nouvelle banque",
        key: "nouvelle-banque",
        route: "/gestion-financiere/nouvelle-banque",
        component: <CreateBank />,
        protected: true,
      },
      {
        name: "Modifier la banque",
        key: "modifier-banque",
        route: "/gestion-financiere/modifier-banque",
        component: <EditBank />,
        protected: true,
      },
      {
        name: "Liste des caisses",
        key: "liste-des-caisses",
        route: "/gestion-financiere/liste-des-caisses",
        component: <CaisseList />,
        protected: true,
      },
      {
        name: "Nouvelle caisse",
        key: "nouvelle-caisse",
        route: "/gestion-financiere/nouvelle-caisse",
        component: <CreateCaisse />,
        protected: true,
      },
      {
        name: "Modifier la caisse",
        key: "modifier-caisse",
        route: "/gestion-financiere/modifier-caisse",
        component: <EditCaisse />,
        protected: true,
      },

      {
        name: "Caisse",
        key: "caisse-details",
        route: "/gestion-financiere/caisse-details",
        component: <Caisse />,
        protected: true,
      },
      {
        name: "Certificat retenue a la ressources",
        key: "generation-de-certificat-retenue-ressource",
        route: "/gestion-financiere/generation-de-certificat-retenue-ressource",
        component: <GenerateRoussoucreRetenue />,
        protected: true,
      },
    ],
  },

  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Users",
    key: "pages",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "Privacy",
        key: "privacy",
        route: "/privacy",
        component: <Privacy />,
        protected: false,
      },

      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
            protected: true,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
            protected: true,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
            protected: true,
          },
          {
            name: "Etat de bon de livraison",
            key: "etat-de-bon-de-livraison",
            route: "/gestion-des-ventes/etat-de-bon-de-livraison",
            component: <DeliveryNoteStatus />,
            protected: true,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "General",
            key: "general",
            route: "/pages/projects/general",
            component: <General />,
            protected: true,
          },
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
            protected: true,
          },
        ],
      },
      {
        name: "RTL",
        key: "rtl",
        route: "/pages/rtl",
        component: <RTL />,
        protected: true,
      },
      {
        name: "Widgets",
        key: "widgets",
        route: "/pages/widgets",
        component: <Widgets />,
        protected: true,
      },
      {
        name: "Charts",
        key: "charts",
        route: "/pages/charts",
        component: <Charts />,
        protected: true,
      },
      {
        name: "Sweet Alerts",
        key: "sweet-alerts",
        route: "/pages/sweet-alerts",
        component: <SweetAlerts />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Home",
        key: "home",
        route: "/home",
        component: <HomePage />,
        protected: false,
      },
      {
        name: "Sign In",
        key: "connexion",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/connexion",
            component: <SignInBasic />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-up/basic",
            component: <SignUpBasic />,
          },
        ],
      },
      {
        name: "Error",
        key: "error",
        collapse: [
          {
            name: "Error 404",
            key: "error-404",
            route: "/authentication/error/404",
            component: <Error404 />,
          },
          {
            name: "Error 500",
            key: "error-500",
            route: "/authentication/error/500",
            component: <Error500 />,
          },
          {
            name: "Error unauthorized",
            key: "error-unauthorized",
            route: "/authentication/error/unauthorized",
            component: <UnauthorizedPage />,
          },
        ],
      },
      {
        name: "Sign Out",
        key: "sign-out",
        route: "/authentication/sign-out",
        component: <SignOut />,
      },
    ],
  },

  { type: "divider", key: "divider-1" },
];

export default routes;
