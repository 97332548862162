import React, { useEffect, useState, useMemo } from "react";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ProductsApi from "api/products";
import ActionCell from "./components/ActionCell";
import { Pagination, Stack, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";

function Promotions() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState("product");
  const [sortOrder, setSortOrder] = useState("asc");
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState(null);

  const handleGetProducts = (page) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }
    setLoading(true);

    ProductsApi.GetPromotedProducts(token, page)
      .then((response) => {
        if (response.data) {
          setProducts(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 10));
          setLoading(false);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
          setLoading(false);
        } else {
          console.error("There has been an error.");
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetProducts(page);
  }, [page, sortField, sortOrder]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (field) => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newOrder);
    setAnchor(null);
  };

  const columns = useMemo(
    () => [
      { Header: t("Produit"), accessor: "product" },
      { Header: t("Prix unitaire"), accessor: "unit_price" },
      { Header: t("Catégorie"), accessor: "category_name" },
      { Header: t("Prix de paquet"), accessor: "total_price" },
      { Header: t("Taux de Réduction"), accessor: "discount_rate" },
      { Header: t("Date de Début"), accessor: "start_date" },
      { Header: t("Date de Fin"), accessor: "end_date" },
      { Header: t("action"), accessor: "action" },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      products.map((product) => ({
        product: product.product_name,
        unit_price: `${product.unit_price} TND`,
        category_name: product.category_name,
        total_price: `${product.total_price} TND`,
        discount_rate: `${Number(product.discount_rate).toFixed(0)} %`,
        start_date: new Date(product.start_date).toLocaleString(),
        end_date: new Date(product.end_date).toLocaleString(),
        action: (
          <ActionCell
            promotionId={product.id}
            onDataUpdate={() => handleGetProducts(page)}
          />
        ),
      })),
      // eslint-disable-next-line 
    [products, page]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <Stack spacing={1} mb={2} direction="row">
            <Link to="/gestion-des-promotions/nouvelle-promotion">
              <SoftButton variant="gradient" color="info">
                + {t("nouvelle promotion")}
              </SoftButton>
            </Link>
            <SoftButton
              variant="outlined"
              color="dark"
              onClick={(e) => setAnchor(e.currentTarget)}
            >
              {t("Trier par")} (⬆⬇)
            </SoftButton>
          </Stack>
          <Menu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
          >
            <MenuItem onClick={() => handleSortChange("product")}>
              {t("Produit")} (
              {sortField === "product" && sortOrder === "asc" ? "⬆" : "⬇"})
            </MenuItem>
            <MenuItem onClick={() => handleSortChange("start_date")}>
              {t("Date de Début")} (
              {sortField === "start_date" && sortOrder === "asc" ? "⬆" : "⬇"})
            </MenuItem>
            <MenuItem onClick={() => handleSortChange("discount_rate")}>
              {t("Taux de Réduction")} (
              {sortField === "discount_rate" && sortOrder === "asc" ? "⬆" : "⬇"}
              )
            </MenuItem>
          </Menu>
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [5, 10, 15, 20, 25],
                  }}
                  canSearch
                  showTotalEntries
                  pagination={{ variant: "gradient", color: "info" }}
                  isSorted
                  noEndBorder
                  table={{ columns, rows }}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 200,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default Promotions;
