import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC4YbwtQGwGjDxsUHrvPnGwiL0tfxrR8DM",
  authDomain: "kamioun-421517.firebaseapp.com",
  projectId: "kamioun-421517",
  // new firebase update: <bucket_name>.appspot.com
  storageBucket: "kamioun-421517.appspot.com",
  appId: "1:965495490819:web:b91dbc9d9e6808eb054136",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

export { storage };
