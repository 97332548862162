import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import EventIcon from "@mui/icons-material/Event";
import { CircularProgress, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import ActionCell from "./components/ActionCell";
import PaymentApi from "api/payment";
import { useTranslation } from "react-i18next";

function BankList() {
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const handleGetBanks = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setLoading(false);
      return;
    }

    PaymentApi.GetBanksList(token)
      .then((response) => {
        if (response.data) {
          setBanks(response.data);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
        console.log(error.response?.data?.msg || error.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetBanks();
  }, []);

  const handleDataUpdate = () => {
    handleGetBanks();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      ) : (
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="85vh"
        >
          <SoftBox py={3}>
            <Link to="/gestion-financiere/nouvelle-banque">
              <SoftButton variant="gradient" color="info">
                + {t("Nouvelle banque")}
              </SoftButton>
            </Link>

            <Grid container spacing={3} mt={1}>
              {banks.map((bank, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ display: "flex", boxShadow: 3, pt: 1 }}>
                    <CardContent
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <SoftBox sx={{ width: "90%" }}>
                        <SoftTypography variant="h5" gutterBottom>
                          {bank.name}
                        </SoftTypography>
                        <SoftTypography
                          variant="body2"
                          color="textSecondary"
                          truncate
                        >
                          {t("Numéro de compte")}: {bank.numAccount}
                        </SoftTypography>

                        <SoftBox
                          display="flex"
                          flexDirection={{
                            xs: "column",
                            xxl: "row",
                          }}
                          alignItems={{ xxl: "center" }}
                          justifyContent="space-between"
                          mt={2}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <EventIcon
                              fontSize="small"
                              sx={{ mr: 1, color: "#007bff" }}
                            />
                            <SoftTypography
                              variant="body2"
                              color="textSecondary"
                            >
                              {t("Créé le")}:{" "}
                              {new Date(bank.created_at).toLocaleDateString()}
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox display="flex" alignItems="center">
                            <Icon sx={{ mr: 1, color: "#28a745" }}>
                              location_on
                            </Icon>
                            <SoftTypography
                              variant="body2"
                              color="textSecondary"
                            >
                              {bank.address}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <ActionCell
                        bankId={bank.id}
                        onDataUpdate={handleDataUpdate}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </SoftBox>
          <Footer />
        </SoftBox>
      )}
    </DashboardLayout>
  );
}

export default BankList;
