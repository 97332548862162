// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import unauthorizedImage from "assets/images/illustrations/lock.png";
import { useTranslation } from "react-i18next";

function UnauthorizedPage() {
  const { d1, d3, d4, d5 } = typography;
  const { t } = useTranslation();

  return (
    <PageLayout white>
      <DefaultNavbar
        routes={pageRoutes}
        transparent
        action={{
          type: "external",
          route: "https://appseed.us/product/soft-ui-dashboard-pro/full-stack/",
          label: "download",
          color: "dark",
        }}
      />
      <SoftBox my={18} height="calc(100vh - 18rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={5}>
              <SoftBox
                fontSize={{
                  xs: d5.fontSize,
                  sm: d4.fontSize,
                  md: d3.fontSize,
                  lg: d1.fontSize,
                }}
                lineHeight={1.2}
              >
                <SoftTypography
                  variant="inherit"
                  color="info"
                  textGradient
                  fontWeight="bold"
                >
                  {t("Unauthorized")}
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="text" fontWeight="bold">
                {t("You do not have permission to access the dashboard!")}
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text" opacity={0.6}>
                  {t("Please contact the administrator if you believe this is a mistake.")}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <SoftButton
                  component={Link}
                  to="/"
                  variant="gradient"
                  color="info"
                >
                  {t("Go to home page")}
                </SoftButton>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={7}>
              <SoftBox
                component="img"
                src={unauthorizedImage}
                alt="unauthorized"
                width="100%"
              />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default UnauthorizedPage;
